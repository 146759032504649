
:root {
    --terry-bg-dark: #111827;
    --terry-text-primary: #f3f4f6;
    --terry-text-secondary: #374151;
    --terry-accent-blue: #3b82f6;
    --terry-accent-purple: #8b5cf6;
    --terry-accent-green: #10b981;

    /* RGB values for accent colors */
    --terry-accent-color-blue-rgb: 59, 130, 246;
    --terry-accent-color-purple-rgb: 139, 92, 246;
    --terry-accent-color-green-rgb: 16, 185, 129;
}

@keyframes terry-gradientFlow {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.terry-wave-svg {
    width: 100%;
    color: var(--terry-bg-dark);
    display: block;
}

.terry-wave-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform: scaleY(-1);
}

.terry-wave-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.terry-showcase-wrapper {
    position: relative;
    min-height: 100vh;
}

.terry-showcase-container {
    position: relative;
    z-index: 10;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #111827, #1f2937);
    color: var(--terry-text-primary);
    padding: 4rem 2rem;
}

.terry-showcase-content {
    max-width: 1200px;
    width: 100%;
}

.terry-main-heading {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 3rem;
    color: var(--terry-text-primary);
    font-weight: bold;
}

.terry-showcase-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    align-items: center;
}

.terry-product-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.terry-product-header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.terry-product-icon {
    width: 3rem;
    height: 3rem;
}

.terry-product-icon.terry-blue { color: #3b82f6; }
.terry-product-icon.terry-purple { color: #8b5cf6; }
.terry-product-icon.terry-green { color: #10b981; }

.terry-product-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--terry-text-primary);
}

.terry-product-description {
    color: var(--terry-text-secondary);
    line-height: 1.6;
}

.terry-product-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.terry-info-section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--terry-text-primary);
}

.terry-feature-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.terry-feature-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--terry-text-secondary);
    transition: all 0.3s ease;
}

.terry-feature-item:hover {
    transform: translateX(10px);
    color: var(--terry-text-primary);
}

.terry-feature-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--terry-accent-blue);
}

.terry-learn-more-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: linear-gradient(to right, #3b82f6, #6366f1);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.terry-learn-more-button:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(59, 130, 246, 0.3);
}

.terry-button-icon {
    width: 1.25rem;
    height: 1.25rem;
}

.terry-product-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.terry-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
}

.terry-product-preview-section {
    display: flex;
    justify-content: center;
}

.terry-product-preview-card {
    position: relative;
    width: 100%;
    max-width: 25rem;
    border-radius: 1rem;
    overflow: hidden;
    padding: 2rem;
    transition: all 0.3s ease;
}

.terry-preview-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: 1;
}

.terry-preview-content {
    position: relative;
    z-index: 2;
}

.terry-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.terry-preview-icon {
    width: 4rem;
    height: 4rem;
    color: rgba(0, 0, 0, 0.3);
}

.terry-navigation-buttons {
    display: flex;
    gap: 0.5rem;
}

.terry-nav-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.terry-nav-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.terry-preview-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 1rem;
}

.terry-preview-description {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 1.5rem;
}

.terry-preview-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.terry-preview-stat {
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
}

.terry-preview-stat h4 {
    font-size: 1.125rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0.5rem;
}

.terry-preview-stat p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
}

.terry-product-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.terry-product-modal-container {
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 12px;
    box-shadow: 0 15px 50px rgba(0,0,0,0.1);
}

.terry-product-modal-content {
    padding: 30px;
    border-radius: 12px;
    position: relative;
    color: #1f2937;
}

.terry-modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.terry-modal-header h2 {
    margin-left: 15px;
    flex-grow: 1;
    color: #1f2937;
}

.terry-modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.terry-modal-close-btn:hover {
    background-color: rgba(0,0,0,0.1);
}

.terry-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.terry-modal-description {
    line-height: 1.6;
    color: #374151;
}

.terry-modal-features h3 {
    margin-bottom: 15px;
    color: #1f2937;
}

.terry-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
}

.terry-feature-card {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.7);
    transition: transform 0.3s, box-shadow 0.3s;
    color: #1f2937;
}

.terry-feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.terry-feature-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: rgba(var(--terry-accent-color-blue-rgb), 0.2);
}

.terry-modal-additional-info {
    margin-top: 20px;
}

.terry-modal-additional-info h4 {
    margin-bottom: 10px;
    color: #1f2937;
}

.terry-modal-additional-info ul {
    list-style: none;
    padding: 0;
}

.terry-modal-additional-info li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    color: #374151;
}

.terry-benefit-check {
    color: #16a34a;
}

.terry-modal-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.terry-nav-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.terry-nav-btn:hover {
    background-color: rgba(0,0,0,0.1);
}

@media (max-width: 1024px) {
    .terry-showcase-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .terry-main-heading {
        font-size: 2.5rem;
    }
}


.terry-product-description {
    color: #9CA3AF; /* Updated description color */
    line-height: 1.6;
}

.terry-feature-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #9CA3AF; /* Updated feature item color */
    transition: all 0.3s ease;
}

.terry-feature-item:hover {
    transform: translateX(10px);
    color: var(--terry-text-primary); /* Keep hover state as before */
}

.terry-modal-product-list {
    margin-top: 20px;
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 20px;
}

.terry-modal-product-list h4 {
    margin-bottom: 15px;
    text-align: center;
}

.terry-modal-product-navigation {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.terry-modal-product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
    opacity: 0.6;
}

.terry-modal-product-item svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.terry-modal-product-item.terry-active {
    opacity: 1;
    background-color: rgba(0,0,0,0.05);
}

.terry-modal-product-item:hover {
    opacity: 0.8;
}
.terry-dot-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    padding: 0;
    margin: 0 4px;
}

.terry-dot-button.terry-active {
    transform: scale(1.2);
}

.terry-dot-button:focus {
    outline: 2px solid white;
    outline-offset: 2px;
}

/* Add focus states to all interactive elements */
button:focus,
.terry-learn-more-button:focus,
.terry-modal-product-item:focus,
.terry-nav-button:focus,
.terry-modal-close-btn:focus,
.terry-nav-btn:focus {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
}

/* Improve color contrast for text */
.terry-product-description {
    color: #d1d5db; /* Lighter gray for better contrast on dark background */
}

.terry-feature-item {
    color: #d1d5db; /* Lighter gray for better contrast */
}

/* Improve modal contrast */
.terry-modal-description {
    color: #1f2937; /* Darker color for better contrast */
    font-weight: 500;
}

/* Skip navigation link for keyboard users */
.skip-to-content {
    position: absolute;
    left: -9999px;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: 9999;
}

.skip-to-content:focus {
    left: 0;
    width: auto;
    height: auto;
    padding: 1rem;
    background: #fff;
    color: #1f2937;
    font-weight: bold;
    text-decoration: none;
}

/* Enhance focus visibility for all interactive elements */
a:focus,
button:focus,
[role="button"]:focus,
[role="tab"]:focus {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
}

/* Ensure sufficient touch target size for mobile */
.terry-nav-button,
.terry-nav-btn,
.terry-modal-close-btn,
.terry-dot-button {
    min-width: 44px;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* CSS Variables for consistent theming */
:root {
    --gradient-primary: linear-gradient(90deg, #3b82f6, #8b5cf6);
    --gradient-background: linear-gradient(135deg, #0f172a 0%, #1e1b4b 100%);
    --gradient-header: linear-gradient(135deg, #f0f9ff, #e0f2fe);
    --color-white: #ffffff;
    --color-white-transparent: rgba(255, 255, 255, 0.9);
    --color-white-faded: rgba(255, 255, 255, 0.8);
    --color-white-more-faded: rgba(255, 255, 255, 0.7);
    --color-white-most-faded: rgba(255, 255, 255, 0.05);
    --color-dark: #1e293b;
    --color-dark-faded: #334155;
    --color-text-gray: #475569;
    --color-text-light-gray: #64748b;
    --font-family: 'Inter', system-ui, -apple-system, sans-serif;
    --border-radius-sm: 12px;
    --border-radius-md: 16px;
    --border-radius-lg: 20px;
    --border-radius-xl: 24px;
    --shadow-sm: 0 4px 12px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 10px 30px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 20px 40px rgba(0, 0, 0, 0.15);
    --shadow-gradient: 0 10px 20px rgba(59, 130, 246, 0.3);
    --shadow-gradient-lg: 0 15px 35px rgba(59, 130, 246, 0.4);
    --transition: all 0.3s ease;
}

/* Overall Section and Background */
.certifications-section {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    padding: 120px 0;
    background: var(--gradient-background);
    color: var(--color-white);
    font-family: var(--font-family);
}

/* Canvas and overlay */
.certifications-canvas,
.certifications-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.certifications-overlay {
    background: radial-gradient(ellipse at top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
}

/* Container and Header */
.certifications-container {
    position: relative;
    z-index: 2;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
    transition: var(--transition);
}

.certifications-header {
    text-align: center;
    margin-bottom: 60px;
}

.certifications-title {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 24px;
    letter-spacing: -0.025em;
    line-height: 1.1;
}

.certifications-highlight {
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.certifications-description {
    font-size: 1.25rem;
    line-height: 1.8;
    color: var(--color-white-transparent);
    max-width: 800px;
    margin: 0 auto;
}

/* Search functionality */
.certifications-search-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
}

.search-box {
    position: relative;
    width: 100%;
    max-width: 550px;
}

.search-input {
    width: 100%;
    padding: 16px 24px 16px 56px;
    border-radius: var(--border-radius-md);
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(12px);
    color: var(--color-white);
    font-size: 1.05rem;
    transition: var(--transition);
    box-shadow: var(--shadow-sm);
}

.search-input:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(139, 92, 246, 0.5);
    box-shadow: 0 6px 24px rgba(139, 92, 246, 0.15);
}

.search-icon,
.clear-search {
    position: absolute;
    top: 16px;
    color: var(--color-white-more-faded);
}

.search-icon {
    left: 20px;
    pointer-events: none;
}

.clear-search {
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

.clear-search:hover {
    color: var(--color-white);
}

/* Category tabs */
.categories-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    margin-bottom: 50px;
    transition: var(--transition);
}

.category-tab {
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: var(--border-radius-sm);
    color: var(--color-white-faded);
    font-weight: 600;
    font-size: 0.95rem;
    cursor: pointer;
    transition: var(--transition);
}

.category-tab:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
    box-shadow: var(--shadow-sm);
}

.category-tab.active {
    background: var(--gradient-primary);
    color: var(--color-white);
    border-color: transparent;
    box-shadow: 0 6px 20px rgba(139, 92, 246, 0.3);
}

/* Certifications grid */
.certifications-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 32px;
    margin-bottom: 60px;
}

.certification-card {
    position: relative;
    background: var(--color-white-most-faded);
    backdrop-filter: blur(12px);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.08);
    transition: all 0.4s ease;
    height: 100%;
    box-shadow: var(--shadow-md);
    animation: fadeInUp 0.5s ease forwards;
    opacity: 0;
}

.certification-card:hover {
    transform: translateY(-8px);
    box-shadow: var(--shadow-lg);
    border-color: rgba(139, 92, 246, 0.3);
}

.certification-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.certification-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.certification-icon-container {
    width: 56px;
    height: 56px;
    border-radius: var(--border-radius-md);
    background: linear-gradient(135deg, rgba(59, 130, 246, 0.2) 0%, rgba(139, 92, 246, 0.2) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    transition: var(--transition);
}

.certification-card:hover .certification-icon-container {
    background: linear-gradient(135deg, rgba(59, 130, 246, 0.3) 0%, rgba(139, 92, 246, 0.3) 100%);
}

.certification-icon {
    font-size: 1.5rem;
}

.certification-name {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--color-white);
    margin: 0 0 8px 0;
}

.certification-description {
    font-size: 1rem;
    color: var(--color-white-faded);
    line-height: 1.6;
    margin-bottom: 24px;
    flex-grow: 1;
}

/* Buttons */
.learn-more-btn,
.consultation-btn,
.contact-btn,
.reset-search {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    background: var(--gradient-primary);
    color: var(--color-white);
    border: none;
    border-radius: var(--border-radius-sm);
    font-weight: 600;
    font-size: 0.95rem;
    cursor: pointer;
    transition: var(--transition);
    gap: 8px;
}

.learn-more-btn {
    width: 100%;
}

.learn-more-btn:hover,
.consultation-btn:hover,
.contact-btn:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-gradient);
}

.learn-more-btn svg,
.consultation-btn svg,
.contact-btn svg {
    transition: transform 0.3s ease;
}

.learn-more-btn:hover svg,
.consultation-btn:hover svg,
.contact-btn:hover svg {
    transform: translateX(4px);
}

.consultation-btn,
.contact-btn {
    padding: 16px 32px;
    font-size: 1.1rem;
    font-weight: 700;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-gradient);
}

.consultation-btn:hover,
.contact-btn:hover {
    box-shadow: var(--shadow-gradient-lg);
}

.reset-search {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 12px 28px;
}

.reset-search:hover {
    background: rgba(255, 255, 255, 0.15);
}

/* No results state */
.no-results {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    color: var(--color-white-more-faded);
    text-align: center;
}

.no-results svg {
    margin-bottom: 24px;
    opacity: 0.7;
}

.no-results p {
    font-size: 1.2rem;
    margin-bottom: 28px;
}

/* CTA section */
.certifications-cta {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12px);
    border-radius: var(--border-radius-xl);
    padding: 50px;
    text-align: center;
    margin-top: 60px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: var(--shadow-lg);
}

.certifications-cta h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 16px;
    color: var(--color-white);
}

.certifications-cta p {
    font-size: 1.1rem;
    color: var(--color-white-transparent);
    margin-bottom: 28px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

/* Sticky Tabs on Scroll */
.certifications-container.scrolled .categories-tabs {
    position: sticky;
    top: 20px;
    background: rgba(15, 23, 42, 0.85);
    backdrop-filter: blur(16px);
    z-index: 10;
    padding: 16px;
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    border: 1px solid rgba(255, 255, 255, 0.08);
    margin-bottom: 30px;
}

/* Modal styles */
.certification-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.certification-modal-overlay.active {
    opacity: 1;
    pointer-events: auto;
}

.certification-modal {
    background: var(--color-white);
    border-radius: var(--border-radius-xl);
    width: 100%;
    max-width: 900px;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.1);
    transform: scale(0.95) translateY(-20px);
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.certification-modal-overlay.active .certification-modal {
    transform: scale(1) translateY(0);
}

/* Custom Scrollbar */
.certification-modal::-webkit-scrollbar {
    width: 8px;
}

.certification-modal::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.certification-modal::-webkit-scrollbar-thumb {
    background: var(--gradient-primary);
    border-radius: 4px;
}

/* Close Button */
.modal-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    cursor: pointer;
    transition: var(--transition);
    z-index: 10;
}

.modal-close-btn:hover {
    background: rgba(0, 0, 0, 0.2);
    transform: rotate(90deg);
}

/* Modal Header */
.certification-modal-header {
    padding: 40px 40px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: var(--gradient-header);
}

.modal-icon-container {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background: var(--gradient-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    color: var(--color-white);
    box-shadow: var(--shadow-gradient);
}

.modal-icon {
    font-size: 2.5rem;
}

.modal-title {
    font-size: 2.2rem;
    font-weight: 800;
    margin: 0 0 8px 0;
    color: var(--color-dark);
}

.modal-subtitle {
    font-size: 1.2rem;
    color: var(--color-text-light-gray);
    margin: 0;
}

/* Modal Content */
.modal-content {
    padding: 40px;
    color: var(--color-dark-faded);
}

.modal-section {
    margin-bottom: 40px;
}

.modal-section:last-child {
    margin-bottom: 0;
}

.modal-section h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 24px;
    color: var(--color-dark);
    display: flex;
    align-items: center;
}

.modal-section h3::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 24px;
    background: var(--gradient-primary);
    margin-right: 16px;
    border-radius: 4px;
}

.modal-section p {
    font-size: 1.05rem;
    line-height: 1.7;
    color: var(--color-text-gray);
}

/* Benefits List */
.benefits-list {
    padding-left: 24px;
    list-style: none;
}

.benefits-list li {
    margin-bottom: 16px;
    position: relative;
    padding-left: 16px;
    font-size: 1.05rem;
    color: var(--color-text-gray);
    line-height: 1.5;
}

.benefits-list li::before {
    content: '';
    position: absolute;
    left: -12px;
    top: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--gradient-primary);
}

/* Process Timeline */
.process-timeline {
    position: relative;
    margin-top: 40px;
    padding-left: 16px;
}

.process-timeline::before {
    content: '';
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    width: 3px;
    background: var(--gradient-primary);
    border-radius: 4px;
}

.process-step {
    position: relative;
    padding-left: 60px;
    margin-bottom: 40px;
}

.process-step:last-child {
    margin-bottom: 0;
}

.step-circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 36px;
    background: var(--gradient-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-weight: 700;
    z-index: 1;
    box-shadow: var(--shadow-gradient);
    transition: var(--transition);
}

.process-step:hover .step-circle {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(59, 130, 246, 0.5);
}

.step-number {
    font-size: 1rem;
    font-weight: 700;
}

.step-content {
    background: rgba(255, 255, 255, 0.6);
    padding: 20px;
    border-radius: var(--border-radius-sm);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: var(--transition);
}

.process-step:hover .step-content {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    border-color: rgba(59, 130, 246, 0.2);
}

.step-content h4 {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: var(--color-dark);
}

.step-content p {
    font-size: 1rem;
    color: var(--color-text-gray);
    margin: 0;
}

/* Modal Footer */
.modal-footer {
    padding: 30px 40px 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    background: var(--gradient-header);
}

/* Keyframe animations */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-50px) scale(0.95);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive styles */
@media (max-width: 1024px) {
    .certifications-title {
        font-size: 3rem;
    }

    .certifications-cta {
        padding: 40px 30px;
    }
}

@media (max-width: 768px) {
    .certifications-section {
        padding: 80px 0;
    }

    .certifications-title {
        font-size: 2.5rem;
    }

    .certifications-description {
        font-size: 1.1rem;
    }

    .certifications-grid {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .certification-modal-header {
        padding: 30px 30px 20px;
        flex-direction: column;
        text-align: center;
    }

    .modal-icon-container {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .modal-content,
    .modal-footer {
        padding: 30px;
    }

    .process-step {
        padding-left: 50px;
    }

    .step-content {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .certifications-title {
        font-size: 2.2rem;
    }

    .certifications-description {
        font-size: 1rem;
    }

    .categories-tabs {
        gap: 10px;
    }

    .category-tab {
        padding: 10px 16px;
        font-size: 0.85rem;
    }

    .certification-modal {
        border-radius: var(--border-radius-md);
    }

    .certification-modal-overlay {
        padding: 15px;
    }

    .modal-close-btn {
        top: 15px;
        right: 15px;
        width: 35px;
        height: 35px;
    }

    .modal-title {
        font-size: 1.8rem;
    }

    .modal-subtitle {
        font-size: 1rem;
    }

    .certification-modal-header,
    .modal-content,
    .modal-footer {
        padding: 20px;
    }

    .consultation-btn,
    .contact-btn {
        width: 100%;
    }

    .process-timeline::before {
        left: 14px;
    }

    .step-circle {
        width: 30px;
        height: 30px;
    }

    .step-number {
        font-size: 0.9rem;
    }
}
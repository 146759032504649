.header-hero {
  position: relative;
  overflow: hidden;
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  text-align: center;
  will-change: transform; /* Hardware acceleration hint */
}

.header-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  width: 90%;

  max-width: 900px;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  animation: pulse 15s infinite alternate;
  will-change: box-shadow; /* Hardware acceleration hint */
}

@keyframes pulse {
  0% {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 8px 32px rgba(66, 133, 244, 0.2);
  }
  100% {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  }
}

.header-brand {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.header-brand-text {
  color: #ffffff;
}

.header-brand-highlight {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-left: 10px;
}

.header-title {
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 1.2;
}

.dynamic-subtitle {
  display: inline-block;
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: all 0.5s ease;
  animation: fadeIn 0.8s ease;
  will-change: opacity, transform; /* Hardware acceleration hint */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-description {
  font-size: clamp(1rem, 3vw, 1.25rem);
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Circular Carousel Styles */
.circular-carousel-container {
  perspective: 1200px;
  height: 260px;
  width: 100%;
  margin: 40px auto;
  position: relative;
  transform-style: preserve-3d;
}

.carousel-card {
  position: absolute;
  width: 280px;
  height: 180px;
  left: calc(50% - 140px);
  top: calc(50% - 90px);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out, box-shadow 0.5s ease;
  overflow: hidden;
  cursor: pointer;
  will-change: transform, opacity; /* Hardware acceleration hint */
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.7s ease;
}

.carousel-card.active .card-image {
  transform: scale(1.05);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.5) 0%, transparent 70%);
  opacity: 0.6;
  transition: opacity 0.5s ease;
  z-index: 1;
  border-radius: 16px;
}

.carousel-card.active {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3),
  0 0 20px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.carousel-card.active .card-overlay {
  opacity: 0.8;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  transform: translateZ(1px);
  position: relative;
  z-index: 3;
}

.card-title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--card-color, #fff);
  letter-spacing: 1px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.card-description {
  font-size: 0.95rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

/* Navigation dots for mobile */
.carousel-nav-dots {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 0;
  margin-bottom: 20px;
}

.nav-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-dot.active {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  transform: scale(1.2);
}

.header-cta-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.header-cta-primary,
.header-cta-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-width: 160px;
}

.header-cta-primary {
  background: linear-gradient(45deg, #00A3FF, #7B68EE);
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 163, 255, 0.3);
  position: relative;
  overflow: hidden;
}

.header-cta-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 163, 255, 0.4);
}

.header-cta-secondary {
  background: transparent;
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.header-cta-secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-2px);
}

.cta-icon {
  transition: transform 0.3s ease;
}

.header-cta-primary:hover .cta-icon,
.header-cta-secondary:hover .cta-icon {
  transform: translateX(5px);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .header-content {
    max-width: 800px;
  }
}

@media (max-width: 991px) {
  .header-content {
    max-width: 700px;
    padding: 30px 20px;
  }

  .circular-carousel-container {
    height: 240px;
  }

  .carousel-card {
    width: 250px;
    height: 160px;
    left: calc(50% - 125px);
    top: calc(50% - 80px);
  }
}

@media (max-width: 768px) {
  .header-hero {
    min-height: 550px;
  }

  .header-content {
    width: 95%;
    padding: 25px 15px;
  }

  .header-description {
    margin-bottom: 20px;
  }

  .circular-carousel-container {
    height: 220px;
    margin: 30px auto;
  }

  .carousel-card {
    width: 240px;
    height: 150px;
    left: calc(50% - 120px);
    top: calc(50% - 75px);
  }

  .card-title {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  .card-description {
    font-size: 0.85rem;
  }

  .header-cta-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }

  .header-cta-primary,
  .header-cta-secondary {
    width: 100%;
    max-width: 280px;
    padding: 10px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .header-hero {
    min-height: 500px;
  }

  .header-content {
    padding: 20px 10px;
  }

  .header-brand {
    margin-bottom: 20px;
  }

  .header-title {
    margin-bottom: 15px;
  }

  .circular-carousel-container {
    height: 200px;
    margin: 20px auto;
  }

  .carousel-card {
    width: 220px;
    height: 140px;
    left: calc(50% - 110px);
    top: calc(50% - 70px);
  }

  .card-content {
    padding: 15px;
  }

  .card-title {
    font-size: 1.3rem;
  }

  .card-description {
    font-size: 0.8rem;
  }
}

/* Support for smaller screens */
@media (max-width: 380px) {
  .header-brand {
    font-size: 1.2rem;
  }

  .carousel-card {
    width: 200px;
    height: 130px;
    left: calc(50% - 100px);
    top: calc(50% - 65px);
  }

  .card-title {
    font-size: 1.2rem;
  }

  .card-description {
    font-size: 0.75rem;
  }
}
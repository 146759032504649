/* Loader.css - Optimized for supersonic speed */
.modern-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  font-family: system-ui, -apple-system, sans-serif; /* Removed extra fonts for faster loading */
  will-change: transform; /* Hardware acceleration hint */
  contain: layout size style; /* Performance optimization */
}

/* Simplified theme options with fewer gradients */
.modern-loader.theme-dark {
  background-color: #0f172a;
  color: #fff;
}

.modern-loader.theme-light {
  background-color: #f8fafc;
  color: #0f172a;
}

.particles-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  contain: strict; /* Maximum isolation for performance */
}

.loader-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  animation: simple-pulse 3s ease-in-out infinite; /* Simplified animation */
  will-change: transform; /* Hardware acceleration hint */
}

.progress-ring {
  transform: rotate(-90deg);
  backface-visibility: hidden; /* Performance optimization */
}

.theme-dark .progress-ring__circle-bg {
  stroke: rgba(255, 255, 255, 0.1);
}

.theme-light .progress-ring__circle-bg {
  stroke: rgba(15, 23, 42, 0.1);
}

.progress-ring__circle-bg,
.progress-ring__circle {
  transition: stroke-dashoffset 0.3s ease-out; /* Simplified transition */
  transform-origin: 50% 50%;
  will-change: stroke-dashoffset; /* Hardware acceleration hint */
}

.progress-ring__circle {
  stroke-linecap: round;
}

.progress-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.progress-number {
  display: flex;
  align-items: baseline;
  font-variant-numeric: tabular-nums;
  line-height: 1;
  margin-bottom: 0.5rem;
}

/* Simplified shadows */
.theme-dark .progress-number {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.theme-light .progress-number {
  text-shadow: 0 0 5px rgba(99, 102, 241, 0.4);
}

.number {
  font-weight: 700;
}

.percent {
  margin-left: 0.25rem;
  opacity: 0.8;
}

.progress-message {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
}

.completion-message {
  opacity: 0;
  transform: translateY(5px);
  animation: fadeIn 0.4s ease forwards; /* Simplified animation */
}

/* Simplified animations for better performance */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes simple-pulse {
  50% {
    transform: scale(1.01);
  }
}

/* Optimized for small screens */
@media (max-width: 480px) {
  .loader-content {
    transform-origin: center center;
    max-width: 90vw;
    max-height: 90vw;
  }

  .progress-message {
    font-size: 0.75rem;
  }
}

/* Enhanced reduced motion support */
@media (prefers-reduced-motion: reduce) {
  .loader-content {
    animation: none;
  }

  .particles-canvas {
    display: none;
  }

  .progress-ring__circle,
  .progress-ring__circle-bg {
    transition: none;
  }

  .completion-message {
    animation: none;
    opacity: 1;
    transform: none;
  }
}